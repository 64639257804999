@import "../_app.scss";

@import '../main.scss';

$main_color: #0e57aa;

$button_color: #008000;

/**/
.text-colour_primary {
  color: $main_color;
}

header {
  background-color: $main_color;
}

form.search input[type="submit"] {
  background-color: $button_color;
}

.breadcrumbs span {
  color: $main_color;
}

.columns li .title {
  color: $main_color;
}

.helpful .option {
  color: $main_color;
}

.related a{
  color: $main_color;
}


body {
  #footer-inner {
    background-color: #302E30;

    ul li {
      color: #727273;
    }

    .logo-holder {
      color: #595959;
      font-size: 13px;
    }
  }
}
