@import "_app.scss";


//colors
$main_color: #1260a3;

$white: #fff;
$color_mountain_mist_approx: #979797;
$color_celeste_approx: #ccc;
$color_gallery_approx: #eee;
$alabaster_100: rgba(250,250,250,1);
$color_mercury_approx: rgba(230,230,230,1);
$color_alto_approx: #dcdcdc;
$color_delta_approx: #a4a4a4;
$color_azure_approx: #455a9e;
$color_gunsmoke_approx: #848484;
$color_desert_storm_approx: #f8f8f8;
$color_rangoon_green_approx: #1d1d1d;
$color_suva_gray_approx: #868686;
$color_fun_blue_approx: #1260a3;
$color_periwinkle_gray_approx: #c3cee1;
$mercury: #e5e5e5;
$color_tundora_approx: #444;
$color_tory_blue_approx: #0e57aa;

//fonts
$font_0: "rancho";
$font_1: "arial";
$font_2: "sans-serif";
$font_3: "myriad pro";
$font_4: "arial";
$font_5: "sans-serif";
$font_6: "rancho";
$font_7: "cursive";
$font_8: "arial";
$font_9: "sans-serif";

//urls
$url_0: url("/compiled/fonts/ranchoregular.eot");
$url_1: url("/compiled/fonts/ranchoregular.eot?#iefix");
$url_2: url("/compiled/fonts/ranchoregular.woff");
$url_3: url("/compiled/fonts/ranchoregular.ttf");
$url_4: url("/compiled/fonts/ranchoregular.svg#ranchoregular");
$url_5: image-url("logo-header.png");
$url_6: image-url("bg-loginDropdown.png");
$url_8: image-url("global/icons/icons-devices.png");
$url_9: image-url("app-signup/icons-moreInfo-li.png");
$url_10: image-url("icons/icons-48px.png");
$url_11: image-url("icons/icons-90px.png");
$url_12: image-url("icons/icons-grey.png");
$url_13: image-url("icons/icons-social-90.png");
$url_14: image-url("bg-testimonial-pointer.png");
$url_15: image-url("icons/arrow-blue-curved-up.png");

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #4c4c4c;
}

h1 {
  margin-top: 130px;
  text-align: center;
  font-size: 45px;
  color: #ffffff;
}
h2 {
  margin-bottom: 40px;
  font-size: 38px;
}
h3 {
  font-size: 30px;
  font-weight: 300;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 18px;
}

a {
  color: inherit;
}

/**/
hr {
  height: 1px;
  width: 100%;
  border: 0;
  margin: 0 0 40px;
  color: #e6e6e6;
  background-color: #e6e6e6;
}

/**/
ul li,
ol li {
  margin-top: 5px;
  margin-bottom: 5px;
}
ul a,
ol a {
  color: #818181;
}
ul a:hover,
ol a:hover {
  color: #616161;
}

/* Utility Styles */
.list-noStyle {
  padding-left: 0;
  list-style: none;
}

/**/
.border-top {
  border-top: 1px solid #ddd;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.disabled {
  cursor: not-allowed;
}
.disabled * {
  opacity: 0.3;
  cursor: not-allowed;
}

/**/
.text-colour_primary {
  color: $main_color;
}

/*=========================

=Layout

=========================*/
header {
  color: #cde6fc;
  height: 400px;
  background-image: url('/compiled/img/global/map.png');
  background-color: $main_color;
  background-repeat: repeat-x;
  background-position: 50% 0%;
}
header div.popular{
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}
header div.popular a{
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

/**/
form.search{
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}
form.search input{
  text-align: left;
  height: 56px;
  line-height: 56px;
  border: none;
  width: 375px;
  vertical-align: bottom;
  font-size: 18px;
}
form.search input[type="search"]{
  padding-left: 28px;
  color: #4c4c4c;

  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}
form.search input[type="submit"] {
  width: 180px;
  padding: 0 0 0 28px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  background-image: url('/compiled/img/global/arrow.svg');
  background-color: $main_color;
  background-position: 138px 50%;
  background-size: 30px;
  background-repeat: no-repeat;
}

/**/
img.logo {
  max-height: 50px;
  margin: 25px 0 0 0;
  float: left;
}

/**/
nav {
  float: right;
  margin: 25px 0 0 0;
  font-weight: 400;
}
nav a {
  display: inline-block;
  line-height: 36px;
  text-transform: uppercase;
  padding: 0 0 0 40px;
  color: #cde6fc;
}
nav a.active {
  padding: 0 20px;
  border: 2px solid #cde6fc;
  border-radius: 18px;
  margin-left: 40px;
  line-height: 32px;
}

/**/
.breadcrumbs {
  height: 50px;
  border: solid 1px #e6e6e6;
  line-height: 50px;
  font-size: 13px;
  text-transform: uppercase;
}
.breadcrumbs a{
  color: #a2a2a2;
}
.breadcrumbs a:after {
  content: '❯';
  margin: 0 20px;
}
.breadcrumbs span {
  color: $main_color;
}
.breadcrumbs i{
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: text-bottom;
}

/**/
footer {
  height: 300px;
  padding-top: 60px;
  margin-top: 140px;
  color: #bcc6d1;
  background-color: #3c4b5b;
}
footer a {
  color: #bcc6d1;
}
footer .title {
  color: #dde9f6;
}
footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* ========================

=Components

=========================*/
/* =box */
.boxes {
  padding-bottom: 40px;
}
.box {
  width: 100%;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  display: inline-block;
  margin-top: 20px;
  margin-: 20px;
  text-align: center;
  background: #fff;
}
a.box {
  text-decoration: none;
  color: inherit;
}
.box:active,
.box:hover {
  border-color: #ccc;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.box span {
  display: block;
  margin-bottom: 20px;
}

.box .icon {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.box .icon .lineIcon {
  width: 100%;
  height: 100%;
  background-position: center;
}
.box .title {
  font-size: 30px;
  font-weight: 300;
  color: #4c4c4c;
}
.box .desc {
  margin-bottom: 10px;
  font-size: 16px;
  color: #818181;
}

/**/
.boxes.home {
  padding-top: 40px;
}

/**/
.columns li .title {
  margin-bottom: 5px;
  font-size: 18px;
  color: $main_color;
}
.columns li hr {
  margin: 20px 0;
}
.columns a,
.columns a > span {
  display: block;
  text-decoration: none;
}
.columns a:hover .title,
.columns a:active .title {
  text-decoration: underline;
}

/**/
.columns .category {
  margin-bottom: 40px;
}
.columns .category:nth-child(3n+1) {
  clear: left;
}

/**/
.category h5 {
  margin-top: 0;
}
.category p {
  font-size: 18px;
  margin: 0 0 5px 0;
}
.category p i {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: text-bottom;
}

/**/
.category .subcats .title {
  font-size: 16px;
}

/**/
.body {
  margin: 0 80px;
}
.articleContent {
  color: #818181;
  font-size: 16px;
}

/**/
.helpful{
  padding-top: 40px;
  border-top: 1px solid #ddd;
  margin-top: 40px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
.helpful .option {
  margin-left: 20px;
  cursor: pointer;
  color: $main_color;
}

.icons i{
  color: #bcc6d1;
  font-size: 18px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  border: solid 2px #bcc6d1;
  border-radius: 16px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.related a{
  margin-bottom: 20px;
  display: block;
  color: $main_color;
}

/* typeahead */
.tt-hint {
  color: #999;
}
.tt-dropdown-menu {
  width: 100%;
  padding: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  text-align: left;
}
.tt-suggestion {
  padding: 3px 20px;
  color: #888;
  cursor: pointer;
}
.tt-suggestion p {
  margin: 0;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background: #0097CF;
}

.category h1,.article h1
{
  display:none;
}
.category form.search, .article form.search
{

  margin-top: 110px;
  margin-bottom: 20px;
}
.category form.search input, .article form.search input {
  text-align: left;
  height: 46px;
  line-height: 46px;
}
.category header, .article header
{
  height: 200px;
}

.toparticles > a {
  display: block;
  color: #818181;
  margin-bottom: .3125rem;
}
.toparticles > a:hover
{
  text-decoration:underline;
}

#lb-contact iframe
{
  width: 100%;
  height: 820px;
}
.home .contactnavlink, .home .contactnavlink.active
{
  display:none;
}

/** Boxes **/
.box {
  .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    .lineIcon {
      width: 100%;
      height: 100%;
      background-position: center;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: cover;
      max-width: 96px;
      max-height: 96px;

      &.lineIcon-bookmarks {
        background-image: url(/compiled/img/kb/bookmarks.png);
      }
      &.lineIcon-mail-open{
        background-image: url(/compiled/img/kb/mail-open.png);
      }
    }
  }
}

/** Contact Us pop up **/
.panel-email {
  width: 100%;
  padding: 0 0 40px;
  float: left;

  ul {
    width: 100%;
    float: left;
    margin: 0;
    list-style: none;

    li {
      line-height: 20px;
      width: 100%;
      float: left;
      clear: both;
      margin: 0 0 5px;

      strong {
        width: 150px;
        float: left;
        font-size: 15px;
        color: #2e2e2e;
      }

      a {
        float: left;
        text-decoration: none;
        font-size: 14px;
        color: #0e57aa;
      }
    }
  }
}

.container {
  padding-bottom: 50px;
}
